export const environment = {
  name: 'dev',
  production: true,
  sentry: {
    dsn: 'https://312c5b13a1bb46f485fe20af17bd0a79@o4504999687421952.ingest.sentry.io/4504999690502144',
    errorDialog: false,
    errorHandling: true,
  },
  google_client_id:
    '1028794112715-0439255fvv8ut47i5cpvs3a3vu0a7j5o.apps.googleusercontent.com',
  mobile_menu_breakpoint: 768,
  mercure_url_suffix: '/mercureHub',
  chat_loading_limit_step: 20,
  chat_groups_loading_limit_step: 5,
  chat_anonymous_firstname: 'John',
  chat_anonymous_lastname: 'Doe',
  chat_new_notification_audio_sound: '/assets/sounds/knock_brush.mp3',
  available_languages: 'fr, en, de , es',
  default_language: 'fr',
  show_all_languagues_inputs: false,
  translatable_suffix: '_translatable',
  disable_loader_patterns:
    '/list,/message-groups,/message-group, /mercureHub, /select',
  loader_hide_timeout: 1000,
  loader_max_duration: 3000,
  poll_answers_prefix: 'answer-',
  datepicker_minyear: 1910,
  use_default_params: false,
  default_app_name: 'Heroes',
  app_name: 'HeRoes',
  app_web_url: 'https://join.daily-heroes.net',
  default_app_codename: 'heroes',
  default_app_api: 'https://api.dev.daily-heroes.net/api/v1/',
  get_list_default_limit: 100,
  authentication_dynamic_uri_code: 'accounts',
  authentication_uri: 'https://accounts.dev.daily-heroes.net/login',
  // RECAPTCHA_V3_SITE_KEY
  recaptcha_v3_site_key: '6Lf-xLImAAAAADRFTKcb1y-3s3NPWOd-qCVsRbdu',
  // Afficher le Account Tip sur la page de login par défaut
  show_account_tip: false,
  // Patterns de routes de l'API pour lesquelles le token n'a pas besoin d'être vérifié
  disable_token_check_patterns:
    '/account, /get_locales, /get_translations, /google_translation, /mercureHub, /first_connection, /oauth, /assets',
  // Fréquence par défaut (en heures) pour l'exercice de breathing
  exercising_breathing_default_frequency: 2,
  // Durée par défaut (en secondes) l'exercice de breathing
  exercising_breathing_default_duration: 60,
  // Image / GIF pour l'exercice de breathing
  exercising_breathing_resource: 'assets/images/breathing_2.gif',
  // Image / GIF pour le Wizard
  wizard_main_image_resource: 'assets/images/360-IMAGE-1.svg',
  // Image / GIF pour la fin du Wizard
  wizard_success_image_resource: 'assets/images/success.jpg',
  // Délai pour la sauvegarde automatique dans les surveys (en secondes)
  surveys_auto_save_interval_delay: 120,
  // Image / GIF pour les surveys cards
  surveys_cards_image_resource: 'assets/yellow_background_for_cards.svg',
  // Image / GIF pour la fleur ANACT
  qwl_flower_image_resource: 'assets/fleurQVT.png',
  // Image / GIF pour les cards
  card_header_default_image_resource: 'assets/card_default_header.png',
  // Image / GIF pour les 360
  articles_header_default_image_resource: 'assets/images/articles1.png',
  // Image / GIF pour les statistics
  statistics_background_default_image_resource:
    'assets/images/interview_01.jpg',

  qvct_header_default_image_resource: 'assets/images/qvct.jpg',
  misc_header_default_image_resource: 'assets/images/misc.jpg',
  pro_default_image_resource: 'assets/images/pro.jpg',
  perso_default_image_resource: 'assets/images/perso.jpg',
  // Image / GIF pour les dashboard
  dashboard_background_default_image_resource: 'assets/images/artboard.svg',
  // Image / GIF pour les bannières
  banner_background_default_image_resource: 'assets/images/header.svg',
  // Redirect URI pour le Slack Button
  slackRedirectUri: 'https://accounts.dev.daily-heroes.net',
  // Client ID pour le Slack Button
  slackClientId: '5164685756322.5365359141776',
  // TinyMce
  tiny_mce_api_key: 'ekcjgdfup9jw6dif9fs4z238l22i4vf37qy0zrdm519des2e',
  // Uploads/Images
  upload_authorized_types: 'application/pdf, image/jpeg, image/jpg, image/png',
  upload_max_size: 1000000,
  photo_authorized_types: 'image/jpeg, image/jpg, image/png',
  photo_max_size: 1000000,
  audio_authorized_types: 'audio/mpeg, video/mp4, audio/x-m4a, audio/x-wav',
  audio_max_size: 1000000,
  default_photo_quality: 100,
  // Google Map
  googlemaps_api_key: 'AIzaSyAK2KwkaHtsCTb4TyX2PZMcQ2ZXEmeRIjY',
  // Spécifique MATE
  disable_change_account: false,
  disable_chat: false,
  disable_notifications: false,
  disable_breathing: true,
  disable_notes: false,
  disable_recaptcha: false,
  disable_gratifications: false,

  // Kizeo OAuth
  kizeo_client_id: '93445387745933839795696857899478',
  kizeo_redirect_url: 'https://accounts.kizeo.com/',
  kizeoAuthentication: true,
  // HotJar
  hotjar_login_id: '3550717',
  hotjar_app_id: '3550713',
  hotjar_version: 6,
  disable_hotjar: false,
  // Kompassify
  kompassify_account_id: 'a38c0a74-29c8-4dc8-b862-09094024bc9a',
  disable_kompassify: true,
  // Path icônes
  fontawesome_path: '/assets/@fortawesome/fontawesome-pro/',
  // Microsoft
  microsoft_client_id: '4dcfc7bf-a856-4c00-9cea-4ce9e8a629ff',
  // Password
  password_min_length: 6,
  // Auth
  disable_web_login: false,
  // Geremy
  ios_store_url: 'https://apps.apple.com/fr/app/geremy/id6553995429',
  android_store_url:
    'https://play.google.com/store/apps/details?id=com.stellarquantum.geremy.mobile',
  ios_intent: 'geremy://main?redirect=true',
  android_intent: 'geremy://main?redirect=true',
}
